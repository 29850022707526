// Form
.form-title {
  font-family: $header-font-family;
  font-weight: 300;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.form-control::placeholder{
  font-weight: 700;
  font-size: .8rem;
  color: #3c3c3c;
  opacity: 1;
}

.form-control,
.form .btn {
  line-height: 1.5;
  border-radius: 0;
  padding: .5rem 1rem;
  transition: all .3s ease;
}
.form-control {
  border-color: #3c3c3c;
  height: 50px;
}
.form-control:focus {
  border-color: $main-color;
}

// radio
.radio input {
  display: none;
}
.radio input:checked+span {
  background: #3c3c3c;
  border: 1px solid #3c3c3c;
}
.radio input+span {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: -4px 8px 0 0;
  background: 0 0;
  border: 1px solid #3c3c3c;
  vertical-align: middle;
  cursor: pointer;
  -webkit-backface-visibility: visible;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

// Form Inline

