.mobileye-page {
  .system-components {
    position: relative;
    padding-top: 0;

    &__line {
      display: none;
      position: absolute;
      background: #b8b8b8;
      z-index: 100;
    }

    &__wrapper {
      .col:nth-child(1) {
        order: 3;
        text-align: left;
      }
      .col:nth-child(2) {
        order: 2;
      }
      .col:nth-child(3) {
        order: 1;
        text-align: right;
      }
    }

    &__eyewatch,
    &__main-block {
      //padding: 48px;
      h3 {
        font-size: 18px;
        text-transform: uppercase;
        margin: 1.25rem 0;
      }
      p {
        font-weight: 300;
      }
    }
    &__eyewatch {
      //position: absolute;
      //bottom: 2rem;
    }
    &__main-block {
      //position: absolute;
      //top: 2rem;
    }
  }
}

@media (max-width: 991px) {
  .mobileye-page {
    .system-components {

    }
  }
}

@media (min-width: 992px) {
  .mobileye-page {
    .system-components {
      &__line {
        display: block;
        top: -278px;
        left: calc(50% + 125px);
        width: 1px;
        height: 280px;
        transform: rotate(24deg);
      }

      &__wrapper {
        .col:nth-child(1) {
          order: 1;
          text-align: right;
        }
        .col:nth-child(2) {
          order: 2;
        }
        .col:nth-child(3) {
          order: 3;
          text-align: left;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .mobileye-page {
    .system-components {
      &__line {
        top: -320px;
        left: calc(50% + 152px);
        height: 325px;
      }
    }
  }
}
