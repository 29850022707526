$vacancy-background-color: #e5e0d7;

.vacancy-page {
  padding-top: 90px;
}

.vacancy {
  padding-top: 95px;

  &__header {

    padding-top: 48px;

    &__title {
      font-weight: 900;

      padding-top: 48px;
    }

    &__description {
      font-size: 1.2rem;
      font-weight: 500;

      padding-top: 16px;
    }
  }


  &__list {
    font-weight: 400;
    margin: 0px -16px;

    padding-bottom: 120px;
  }

  &__block {
    padding: 16px;
    //margin: 0 16px;

    &__content {
      background-color: $vacancy-background-color;
      padding: 24px;
      min-height: 800px;
    }

    &__title {
      font-size: 1.3rem;
      font-weight: 700;
      padding-bottom: 16px;
    }

    &__links {
      padding-top: 100px;
    }
  }
}

@media (min-width: 992px) {
  .vacancy {
    &__block {
      width: 50%;
      &__content {
        padding: 24px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .vacancy {
    &__block {
      width: 50%;
      &__content {
        padding: 48px;
        min-height: 800px;
      }
    }
  }
}

