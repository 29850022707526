// Typography
$main-font-family: 'KievitPro', 'Helvetica Neue', Arial,  sans-serif;
$header-font-family: 'KievitPro', 'Helvetica Neue', Arial,  sans-serif;
$footer-font-family: 'KievitPro', 'Helvetica Neue', Arial,  sans-serif;

$main-text-color: #212121;
$header-text-color: #212121;

// Brand
$main-color: #2e7d32;
$secondary-color: #003281;

$background-color-light: #ffffff;
$background-color-dark: #1b213e;
$background-color-grey: #e5e0d7;

$modal-color: #F5F5F5;
