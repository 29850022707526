// Navbar
.navbar {
  padding: 0;
  //border-bottom: 1px solid $border-color;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.12), 0 1px 2px 0 rgba(0,0,0,.24);

  /** For extra menu **/
  flex-wrap: wrap;

  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
  }

  .main-menu {
    padding: 1rem 2rem;
    line-height: 30px; // Height of logo and support icon

    .nav-item {
      padding: 0 .5rem;
    }

    &__center {
      margin: 0 auto;
      font-size: 18px;
      text-transform: uppercase;
    }

    &__right {
      font-size: 18px;
    }
  }

  .extra-menu {
    width: 100%;
    padding: .5px 1rem;
  }

  .nav-separator {
    width: 1px;
    margin: 0 1rem;
  }

  // Collapsed items
  .nav-link {
    //padding: 0 1rem;
    i {
      padding-left: 8px;
    }
  }

  .product-menu-item {
    display: block;
    min-height: 218px; // For smooth animation
    text-align: center;
    padding: 1.5rem 1rem;

    &__image {
      margin-bottom: .5rem;
    }

    &__title {
      margin: 0;
    }
  }

  .company-menu-item {
    padding: 1.5rem 1rem;

    &--about {
      &__title {
        font-weight: bold;
        padding: .5rem 0;
        margin-bottom: 0;
      }
      &__description {

      }
    }
    &--nav {

    }
  }

  .product-menu-item,
  .company-menu-item .nav-link {
    color: #757575;

    &:hover {
      color: #424242;
      text-decoration: none;
    }
  }
}

.navbar-light {

  .main-menu {
    background-color: #ffffff;
  }

  .extra-menu {
    color: black;
    background-color: #f8f6f1;
  }

  .nav-separator {
    background: $border-color;
  }

  .navbar-nav .nav-link {
    color: #757575;
  }
  .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #424242;
  }

  .logo-menu-white,
  .support-icon-white {
    display: none;
  }
}

.navbar-transparent {
  background-color: transparent;

  .main-menu {
    background-color: transparent;
  }

  .extra-menu {
    color: black;
    background-color: #f8f6f1;
  }

  .nav-separator {
    background: #ffffff;
  }

  .navbar-nav .nav-link {
    color: #ffffff;
  }

  .navbar-toggler {
    border: 1px solid #ffffff;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
  }

  .logo-menu,
  .support-icon {
    display: none;
  }
}
