.ifleet-page {
  .reports {
    background-color: #404c88;

    .container {
      position: relative;
    }

    &__title-01 {

    }
    &__title-02 {

    }
    &__description {

    }
  }
}

@media (min-width: 992px) {
  .ifleet-page .reports {
    padding-top: 0;
    padding-bottom: 0;

    min-height: 600px;
    background-image: url(/assets/img/solutions/ifleet/reports.jpg);
    background-position: center center;
    background-size: auto 600px;
    background-repeat: no-repeat;

    &__title-01 {
      width: 200px;

      position: absolute;
      top: 70px;
      left: 145px;

      font-size: 2rem;
      margin-top: 0;
      line-height: 1.2;
    }
    &__title-02 {
      width: 400px;

      position: absolute;
      top: 70px;
      left: 365px;

      font-size: 2rem;
      margin-top: 0;
      line-height: 1.2;
    }
    &__description {
      padding-top: 205px;
      padding-left: 36px;
      max-width: 500px;
    }
  }
}
@media (min-width: 1200px) {
  .ifleet-page .reports {
    &__title-01 {
      left: 240px;
    }
    &__title-02 {
      left: 455px;
    }
    &__description {
      padding-top: 290px;
    }
  }
}
