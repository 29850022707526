.ifleet-page {
  .header {
    background-repeat: no-repeat;
    background-image: url(/assets/img/solutions/ifleet/header.jpg);
    background-size: cover;
    background-position: center bottom;

    min-height: 800px;

    &__logo,
    &__text {
      padding-top: 140px;
    }

    &__logo {
      color: rgba(255, 255, 255, .5);
      font-weight: 700;
      font-size: 11rem;
      padding-right: 48px;
    }

    &__text {
      color: #ffffff;
      h1 {
        font-weight: 900;
        font-size: 2.3rem;
        margin-bottom: 36px;
      }
      p {
        font-weight: 400;
        font-size: 1.3rem;
      }
    }
  }
}

@media (max-width: 992px) {
  .ifleet-page {
    .header__logo {
      font-size: 7rem;
    }
    .header__logo,
    .header__text {
      padding-top: 36px;
    }
  }
}

@media (max-width: 767px) {
  .ifleet-page {
    .header {
      min-height: 400px;
    }
    .header__logo,
    .header__text {
      padding-top: 24px;
    }
    .header__logo {
      font-size: 3.5rem;
    }
    .header__text {
      font-size: 1.5rem;
      font-weight: 400;
    }
  }
}


