// Buttons
@media (max-width: 991px) {
  .btn-responsive {
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .btn-responsive + .btn-responsive {
    margin-top: 1rem;
  }
}
@media (min-width: 992px) {
  .btn-responsive + .btn-responsive {
    margin-left: 3rem;
  }
}


.btn {
  line-height: 1.5;
  border-radius: 0;
  padding: .5rem 2.5rem;
  border: 2px solid transparent;
  transition: all .3s ease;
}
.btn-primary {
  color: #fff;
  background-color: $main-color;
  background-image: none;
  border-color: $main-color;
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active,
  &:hover, &.active, &.focus, &:active, &:focus, .open>&.dropdown-toggle {
    color: #fff;
    background-color: darken($main-color, 10%);
    border-color: darken($main-color, 10%);
  }
}
.btn-outline-primary {
  color: $main-color;
  background-color: transparent;
  background-image: none;
  border-color: $main-color;
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active,
  &:hover, &.active, &.focus, &:active, &:focus, .open>&.dropdown-toggle {
    color: #fff;
    background-color: $main-color;
    border-color: $main-color;
  }
}

.btn-secondary {
  color: #fff;
  background-color: $secondary-color;
  background-image: none;
  border-color: $secondary-color;
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active,
  &:hover, &.active, &.focus, &:active, &:focus, .open>&.dropdown-toggle {
    color: #fff;
    background-color: darken($secondary-color, 10%);
    border-color: darken($secondary-color, 10%);
  }
}
.btn-outline-secondary {
  color: $secondary-color;
  background-color: transparent;
  background-image: none;
  border-color: $secondary-color;
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active,
  &:hover, &.active, &.focus, &:active, &:focus, .open>&.dropdown-toggle {
    color: #fff;
    background-color: $secondary-color;
    border-color: $secondary-color;
  }
}


.btn-white-outline {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  &:hover, &.active, &.focus, &:active, &:focus, .open>&.dropdown-toggle {
    color: $main-color;
    background-color: #fff;
    border-color: #fff;
  }
}