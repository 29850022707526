.fuelomat-page {
  position: relative;
  .video {
    &__btn {
      position: absolute;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
    }
  }
}
