.ifleet-page {
  .how-it-works {
    &__wrapper {
      min-height: 420px;
      height: 100%;
      padding: 12px 0;
    }
    &__block {
      height: 100%;
      background-color: #e5e0d7;
      padding: 24px;
      padding-top: 48px;
    }

    &__number {
      position: absolute;
      top: 8px;
      right: 32px;
      color: #ffffff;

      font-size: 2rem;
    }

    &__image {
      text-align: center;
      height: 250px;
      line-height: 250px;
     img {
       max-height: 250px;
       vertical-align: bottom;
     }
    }

    &__title {
      padding: 16px 0;
      font-weight: 900;
    }

    &__description {

    }

    &__front {
      height: 100%;
      display: none;
      flex-direction: column;

      a {
        margin-top: auto;
      }

      &.active {
        display: flex;
      }
    }
    &__back {
      height: 100%;
      display: none;
      flex-direction: column;

      a {
        margin-top: auto;
      }

      &.active {
        display: flex;
      }
    }
  }
}

@media (min-width: 768px) {
  .ifleet-page .how-it-works {
    &__block {
      &:hover {
        >.how-it-works__front {
        }
        >.how-it-works__back {
        }
      }
    }
  }
}

