// All sections
.section {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .background-header {
    display: none;
  }
  .title {
    position: relative;
    font-family: $header-font-family;
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 2.5rem;
  }
  p {

  }
}
.section--light {
  //background-color: $background-color-light;
  .title {
    color: $header-text-color;
  }
}
.section--grey {
  background-color: $background-color-grey;
}
.section--dark {
  background-color: $background-color-dark;
  color: #fff;

  .title {
    color: #fff;
  }

  hr {
    border-top-color: #fff;
  }

  a{
    color: white;
  }
}

@media (min-width: 576px) {

}
@media (min-width: 992px) {
  .section {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
@media (min-width: 1200px) {

}