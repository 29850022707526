// owl carousel
.owl-carousel {
  position: relative;
  padding: 0 60px;
}
.owl-carousel .item{
  //margin: 0 32px;
}
// owl carousel arrows
.owl-carousel {
  margin: 2rem 0;
}
.owl-carousel .owl-nav [class*=owl-] {
  margin: 0;
  position: absolute;
  top: calc(50% - 20px);
  transition: all 0.3s cubic-bezier(0.3, 0.9, 0.7, 0.95);
}
.owl-carousel .owl-nav .owl-prev {
  left: 0;
}
.owl-carousel .owl-nav .owl-next {
  right: 0;
}
.owl-carousel .owl-nav .owl-prev span,
.owl-carousel .owl-nav .owl-next span {
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 20px;
  height: 40px;
}
.owl-carousel .owl-nav .owl-prev span {
  background-image: url("../../assets/img/carousel/prev.png");
}
.owl-carousel .owl-nav .owl-next span {
  background-image: url("../../assets/img/carousel/next.png");
}
// owl carousel dots
.owl-carousel .owl-dot {
  display: inline-block;
  zoom: 1;
  cursor: pointer;
  user-select: none;
}
.owl-carousel .owl-dots .owl-dot span {
  width: 14px;
  height: 14px;
  margin: 0 8px;
  background: transparent;
  border: 2px solid white;
  border-radius: 50%;
  display: inline-block;
  -webkit-backface-visibility: visible;
  transition: all .3s ease;
}
.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
  background: white;
  border: 2px solid white;
}