// Header
// Based on Start Bootstrap - Full Slider (https://startbootstrap.com/template-overviews/full-slider)

.header {
  .carousel-item {
    height: 100vh;
    min-height: 300px;
    background: no-repeat center bottom scroll;
    background-size: cover;
  }

  // Первый вариант
  .carousel-indicators {
    li {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin: 0 .5rem;
      background-color: #BCB5A8;
      opacity: .5;
      &:hover {
        cursor: pointer;
        background-color: $main-color;
      }
    }
    .active {
      background-color: $main-color;
      opacity: 1;
    }
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    width: 30px;
    height: 107px;
  }
  .carousel-control-prev-icon {
    background-image: url('/assets/img/main-page-slider/arrow-prev.png');
  }
  .carousel-control-next-icon {
    background-image: url('/assets/img/main-page-slider/arrow-next.png');
  }

  // Второй вариант
  .carousel-controls {
    position: absolute;
    right: 5%;
    bottom: 5%;
    align-items: center;
    justify-content: center;
    text-align: center;

    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      display: block;
      width: 70px;
      height: 35px;
      border: 2px solid white;
    }
    .carousel-control-prev {
      margin-left: -68px;
      margin-bottom: -2px;
    }
    .carousel-control-next-icon, .carousel-control-prev-icon {
      width: 28px;
      height: 5px;
    }
    .carousel-control-prev-icon {
      background-image: url('/assets/img/main-page-slider/arrow-left.png');
    }
    .carousel-control-next-icon {
      background-image: url('/assets/img/main-page-slider/arrow-right.png');
    }
  }



  .carousel-item {
    .carousel-caption {
      position: absolute;
      top: 15%;
      bottom: auto;
      right: auto;
      left: 0;
    }
  }
}

@media (max-width: 1199px) {
  .header .carousel-item .carousel-caption{
    position: absolute;
    top: 15%;
    bottom: auto;
    right: 0;
    left: 0;
  }
}
