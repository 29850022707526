// Typo
body {
  font-family: $main-font-family;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.1px;
  color: $main-text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
b,
strong,
.strong {
  font-weight: 700;
}
p {
  font-size: 1.1rem;
  margin: 0 0 20px;
}

.small {
  font-size: 1rem;
  color: #515151;
}
