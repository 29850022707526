.mobileye-page {
  .auto-brands {

    &__list {
      border: 1px solid #cdc6b9;
      margin-right: 36px;
    }

    &__block {
      width: 25%;
      height: 100px;
      border: 1px solid #cdc6b9;
      text-align: center;
      padding: 24px;
      display: flex;
      align-items: center;

      img {
        margin: 0 auto;
      }
    }
  }
}

@media (min-width: 1200px) {
  .mobileye-page {
    .auto-brands {
    }
  }
}
