.ifleet-page {
  .management {
    &__line {
      display: none;
    }
    .col {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}

@media (min-width: 992px) {
  .ifleet-page .management {
    .col {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
    &__line {
      display: block;
      position: absolute;
      top: 475px;
      left: calc(50% - 80px);
      width: 3px;
      height: 250px;
      transform: rotate(-60deg);
      background: #aaabb9;
      z-index: 100;
    }
    .col:nth-child(3) {
      margin-top: -120px;
    }
  }
}
@media (min-width: 1200px) {
  .ifleet-page .management {
    &__line {
      top: 445px;
      left: calc(50% - 127px);
      height: 300px;
    }
    .col:nth-child(3) {
      margin-top: -150px;
    }
  }
}

