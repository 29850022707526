$vacancy-background-color: #e5e0d7;

.company-page {
  padding-top: 80px;

  .company__header {

    padding-top: 48px;

    &__title {
      font-weight: 900;

      padding-top: 48px;
    }

    &__description {
      font-size: 1.2rem;
      font-weight: 500;

      padding-top: 16px;
    }
  }

  .clients,
  .partners {

    &__list {
      //border: 1px solid #e5e0d7;
      //margin-right: 36px;
    }

    &__block {
      width: 100%;
      text-align: center;
      padding: 12px;

      img {
        margin: 0 auto;
        filter: grayscale(100%);
        &:hover {
          filter: grayscale(0%);
        }
      }
    }
  }

  .reviews {
    .review {
      padding: 24px;
      padding-bottom: 48px;
      background-color: $background-color-grey;
      height: 100%;

      &__title {
        font-size: 1.4rem;
        color: $secondary-color;
        padding-bottom: 16px;
      }
      &__text {
        font-size: 1.2rem;
      }
      &__link {

      }
    }

    .owl-carousel .owl-item img {
      display: block;
      width: auto;
      margin: 0 auto;
    }
    .owl-carousel {
      padding: 0;
    }
    .owl-nav {
      position: absolute;
      bottom: 2px;
      right: 16px;
    }
    .owl-carousel .owl-nav [class*=owl-] {
      margin: 0;
      position: relative;
      top: auto;
    }
    .owl-carousel .owl-nav .owl-prev {
      left: auto;
    }
    .owl-carousel .owl-nav .owl-next {
      right: auto;
    }
    .owl-carousel .owl-nav .owl-prev span,
    .owl-carousel .owl-nav .owl-next span {
      position: relative;
      display: block;
      width: 70px;
      height: 35px;
      border: 1px solid #a7a49d;
      background-size: auto;
    }
    .owl-carousel .owl-nav .owl-prev span {
      background-image: url("../../assets/img/carousel/arrow-left.png");
    }
    .owl-carousel .owl-nav .owl-next span {
      background-image: url("../../assets/img/carousel/arrow-right.png");
    }
  }


  #contact-info,
  #contact-maps {
    &>div {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}

@media (min-width: 576px) {
  .company-page {
    .clients,
    .partners {
      &__block {
        width: 50%;
      }
    }
  }
}

@media (min-width: 992px) {
  .company-page {
    .clients,
    .partners {
      &__block {
        width: 25%;
      }
    }
  }
}

