.mobileye-page {
  padding-top: 80px;

  .logo-background {
    background-image: url(/assets/img/solutions/mobileye/logo-background.png);
    background-repeat: no-repeat;
    background-size: 1500px 1500px;
    background-position: calc(50% - 300px) -390px; // Отступ от центра и от верхнего края
  }
}
