.ifleet-page {
  .control {

  }
}

@media (min-width: 768px) {
  .ifleet-page .control {
    padding-top: 0;
    padding-bottom: 0;
  }
}
