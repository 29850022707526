.contacts-page {
  padding-top: 90px;

  &__header {

    padding-top: 48px;

    &__title {
      font-weight: 900;

      padding-top: 48px;
    }

    &__description {
      font-size: 1.2rem;
      font-weight: 500;

      padding-top: 16px;
    }
  }

  .trial {
    &__form {
      max-width: 400px;
      margin: 0 auto;

      .radio-group {
        .radio {
          display: block;
        }
      }

      .form-control {
        background-color: transparent;
      }
    }
  }
}

@media (min-width: 992px) {
  .contacts-page {
    .trial .col:nth-child(2) {
      padding-left: 100px;

      .title {
        display: none;
      }
    }
  }
}

