.ifleet-page {
  .trial {
    &__form {
      max-width: 400px;
      margin: 0 auto;

      .radio-group {
        .radio {
          display: block;
        }
      }

      .form-control {
        background-color: transparent;
      }
    }
  }
}