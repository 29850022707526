.mobileye-shield-page {
  .devices {
    &__list {
      margin-top: -100px;
    }
    &__image {

    }
    &__title {
      font-weight: 500;
    }
    &__description {
      font-size: .9rem;
    }
  }
}

@media (min-width: 1200px) {
  .mobileye-shield-page {
    .devices {
      &__title {
        text-align: center;
      }
      &__image {
        text-align: center;
      }
    }
  }
}
