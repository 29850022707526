$vendors-background-color: #e5e0d7;
.vendors-page {
  padding-top: 90px;
}

.cr {
  position: relative;
}

.ca {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cy {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cx {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.vendors {
  &__header {
    padding-top: 48px;
    &__title {
      font-weight: 900;
      padding-top: 48px;
    }
    &__description {
      font-size: 1.2rem;
      font-weight: 500;
      padding-top: 16px;
    }
  }
  &__block {
    margin-top: 48px;
    margin-bottom: 144px;
  }
  &__content {
    height: 100%;
    &__title {
      font-size: 2rem;
      font-weight: 900;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    &__description {
      font-size: 1.2rem;
      padding-top: 16px;
    }
    &__image {
      margin-top: auto;
      padding-top: 72px;
    }
  }
  &__images {
    position: relative;
    height: 100%;
    &__big {
      padding-top: 85px;
      //padding-bottom: 60px;
      img {
        max-width: 100%;
      }
    }
    &__title {
      position: absolute;
      bottom: -34px; // Это разница между baseline и выносными элементами типа y или g
      //bottom: -10%;
    }
  }
}

// variations
.vendors__block--right {
  & > .col:first-child {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .vendors__content__image {
    text-align: right;
  }
  .vendors__images__title {
    right: -30%;
  }
}

.vendors__block--left {
  .vendors__content__image {
    text-align: left;
  }
  .vendors__images__title {
    left: -30%;
  }
}

// TODO: Rewrite
@media (max-width: 991px) {
  .vendors__block {
    margin-top: 48px;
    margin-bottom: 24px;
  }
  .vendors__images__title {
    display: none;
  }
  .vendors__content__image {
    display: none;
  }
  .vendors__images__big {
    padding-top: 16px !important;
  }
}

@media (min-width: 992px) {
  .ca-lg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cy-lg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .cx-lg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .ca-lg-0 {
    top: 0;
    left: 0;
    transform: none;
  }
  .cx-lg-0 {
    left: 0;
    transform: none;
  }
  .cy-lg-0 {
    top: 0;
    transform: none;
  }
}