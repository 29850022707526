// Footer
$footer-background-color: #e5e0d7;
$footer-dark-color: rgba(0,0,0, 1);
$footer-light-color: rgba(255,255,255,.7);
$footer-divider-color: rgba(0,0,0,.3);


.footer {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 1rem;
  font-size: .9rem;
  background-color: $footer-background-color;
  p {
    font-size: .9rem;
  }
}

.footer_main {
  .icon {
    vertical-align: baseline;
    padding-right: .5rem;
  }

  a {
    color: $footer-dark-color;
    font-weight: bold;
    opacity: .7;
    &:hover {
      color: $footer-dark-color;
      opacity: 1;
    }
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid $footer-divider-color;
  }
}

.footer__nav {
  padding-left: 0;
  margin-bottom: 20px;

  li {
    display: inline-block;
    font-weight: bold;
  }

  li + li {
    margin-left: 0;
  }

  &__link {
    color: #003281;
    padding: 4px;
    &--lang {
      &.active {
        color: #3a843d;
      }
      color: #929291;
    }
  }
}

.background-logo {
  background-image: url(/assets/img/footer/symbol.png);
  background-size: 615px;
  background-repeat: no-repeat;
  background-position: calc(100% + 200px) 54px;
}

@media (min-width: 992px) {
  .empty {
    height: 34px
  }
}
