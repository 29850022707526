.mobileye-shield-page {
  .universal-installation {
    &__images {
      &>div {
        min-width: 100px;
        padding: 16px 24px;
        text-align: center;
        border: 1px solid #2f396c;
      }
    }
  }
}
