.main-page {
  .carousel-item {
    .background-image {
      height: 100%;
      width: 100%;
      display: block;
      background-size: cover;
      background-position: bottom center;
      background-repeat: no-repeat;
    }
    &--mobileye .background-image {
      background-image: url(/assets/img/main-page-slider/slide-01.jpg);
    }
    &--mobileye-shield .background-image {
      background-image: url(/assets/img/main-page-slider/slide-02.jpg);
    }
    &--ifleet .background-image {
      background-image: url(/assets/img/main-page-slider/slide-03.jpg);
    }
    &--fuelomat .background-image {
      background-image: url(/assets/img/main-page-slider/slide-04.jpg);
    }
  }
  .carousel-caption {
    height: 100%;
    min-width: 100%;
    text-align: left;
    padding-bottom: 5%;
    //padding-top: 150px;
    > .container,
    .d-flex {
      height: 100%;
    }
  }
  .p-carousel-caption {
    padding: 0;
  }
  .header {
    .title {
      color: #ffffff;
      font-weight: 900;
      max-width: 450px;
    }
    .btn {
      max-width: 450px;
      margin: 24px 0;
      font-weight: 500;
    }
    .logo {
      color: rgba(255, 255, 255, 0.85);
      font-weight: 900;
      margin-bottom: 100px;
    }
  }
}

@media (min-width: 768px) {
  .p-carousel-caption {
    padding: 100px 48px !important;
  }
}

@media (max-width: 1199px) {
  .header .carousel-item .carousel-caption {
    text-align: center;
    padding: 100px 48px;
  }
  .main-page .header {
    .title,
    .btn,
    .logo {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (max-width: 320px) {
  .header {
    .logo {
      font-size: 3rem;
    }
    .title {
      font-size: 1.7rem;
    }
  }
}
@media (min-width: 321px) and (max-width: 575px) {
  .header {
    .logo {
      font-size: 3.5rem;
    }
    .title {
      font-size: 2rem;
    }
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .header {
    .logo {
      font-size: 5rem;
    }
    .title {
      font-size: 2.3rem;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .header {
    .logo {
      font-size: 6rem;
    }
    .title {
      font-size: 2.3rem;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .header {
    .logo {
      font-size: 7rem;
    }
    .title {
      font-size: 2.3rem;
    }
  }
}
@media screen and (min-width: 1200px) {
  .header {
    .logo {
      font-size: 8rem;
    }
    .title {
      font-size: 2.3rem;
    }
  }
}

