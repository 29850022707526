.ifleet-page {
  .integrations {
    &__block {
      width: 50%;
      padding: 4px;
    }
    &__description {
      text-align: center;
      font-size: .8rem;
      font-weight: 500;
      padding: 8px;
    }
    &__wrapper {
      &--first .integrations__block {
        align-self: flex-end;
      }
    }
  }
}

@media (min-width: 768px) {
  .ifleet-page .integrations {
    &__block {
      width: 33%;
      padding: 4px;
    }
  }
}

@media (min-width: 992px) {
  .ifleet-page .integrations {
    &__block {
      width: 20%;
      padding: 4px;
    }
  }
}
