.mobileye-shield-page {
  .header {
    background-repeat: no-repeat;
    background-image: url(/assets/img/solutions/mobileye-shield/header.jpg);
    background-size: cover;

    min-height: 800px;

    &__logo,
    &__image,
    &__text {
      padding-top: 140px;
    }

    &__image {
      padding-right: 48px;
    }

    &__logo {
      color: rgba(255, 255, 255, .5);
      font-size: 11rem;
      font-weight: 500;

      .strong {
        font-weight: 900;
      }
    }

    &__text {
      color: #ffffff;
      h1 {
        font-weight: 900;
        font-size: 2.3rem;
        margin-bottom: 36px;
      }
      p {
        font-weight: 400;
        font-size: 1.3rem;
      }
    }
  }
}

@media (max-width: 767px) {
  .mobileye-shield-page {
    .header {
      min-height: 400px;
    }
    .header__logo {
      font-size: 3.5rem;
    }
    .header__text {
      h1 {
        font-weight: 500;
      }
      font-size: 1.5rem;
      font-weight: 400;
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }
}

