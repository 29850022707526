.mobileye-page {
  .digital-block {
    &__block {
      &:first-child{
        padding-left: 0;
      }
      padding-left: 48px;
    }

    &__digital {
      color: $secondary-color;
      font-size: 3rem;
      font-weight: 900;
    }
    &__text {

    }
  }
}
