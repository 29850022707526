.background-form {
  margin-top: 112px;
  margin-bottom: 112px;

  background-image: url(/assets/img/common/background-form.png);
  background-repeat: no-repeat;
  background-size: 660px 660px;
  background-position: calc(50% - 330px) 0; // Хз как это работает
}
.background-form-contact {
  margin-top: 112px;
  margin-bottom: 112px;

  background-image: url(/assets/img/common/background-form.png);
  background-repeat: no-repeat;
  background-size: 660px 660px;
  background-position: calc(100% - 110px) 0; // ХЗ как это работает
}