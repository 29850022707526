.mobileye-page {
  .header {
    background-repeat: no-repeat;
    background-image: url(/assets/img/solutions/mobileye/header.jpg);
    background-size: cover;
    background-position: center bottom;

    min-height: 800px;

    &__logo {
      color: rgba(255, 255, 255, .5);
      font-weight: 700;
      font-size: 11rem;

      padding-top: 140px;
    }

    &__text {
      max-width: 600px;

      color: #ffffff;
      font-weight: 900;
      font-size: 2.3rem;
    }
  }
}

@media (max-width: 767px) {
  .mobileye-page {
    .header {
      min-height: 400px;
    }
    .header__logo {
      font-size: 3.5rem;
    }
    .header__text {
      font-size: 1.5rem;
      font-weight: 400;
    }
  }
}
