.fuelomat-page {
  .advantages {
    .media {
      margin-bottom: 48px;
      min-height: 150px;
    }
    .media-image {
      min-width: 90px;
      margin-right: 24px;
    }
    .media-body {
      padding: 0 24px;
    }
  }
}
