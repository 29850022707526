.fuelomat-page {
  .details {
    margin-top: 112px;

    &__wrapper {
      max-width: 80%;
      margin: 0 auto;
      text-align: center;
    }

    &__title {
      font-size: 1.75rem;
      font-weight: 500;
      line-height: 1.2;
    }

    &__link {
      padding: 24px 0;
    }

    &__copyright {
      color: #515151;
      font-size: .8rem
    }
  }
}
