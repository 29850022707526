html, body {
  min-height: 100%;
  height: 100%;
  background-color: #ffffff;
}

button, .radio {
  cursor: pointer;
}

iframe {
  border-width: 0;
}
