.mobileye-shield-page {
  .visual-signals {
    padding-top: 4rem;
    .owl-carousel .owl-item img {
      margin: 0 auto;
      display: block;
      width: auto;
      height: 200px;
      padding-right: 48px;
    }
    h3 {
      font-weight: 100 !important;
    }

    &__title-wrapper {

    }
    &__digit {
      color: #003281;
      font-size: 3rem;
      font-weight: 400;
    }
    &__subtitle {
      font-size: 1.3rem;
      font-weight: 500;
      padding: 16px 0;
    }
  }
}

@media (min-width: 992px) {
  .mobileye-shield-page {
    .visual-signals {
      .owl-carousel .owl-item img {
        display: inline-block;
        height: 300px;
        padding-right: 48px;
      }

      .item .col:nth-child(2) {
        padding-left: 24px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .mobileye-shield-page {
    .visual-signals {
      padding-top: 8rem;

      .owl-carousel .owl-item img {
        display: inline-block;
        width: auto;
        height: auto;
      }

      &__title-wrapper {
        position: relative;
        width: 100%;
        .title {
          position: absolute;
          top: -4rem;
          right: 0;
          width: 40%;
          text-align: right;
        }
      }

      .item .col:nth-child(2) {
        padding-left: 48px;
      }
    }
  }
}
