/* This stylesheet generated by Transfonter (https://transfonter.org) on December 7, 2017 2:24 PM */

@font-face {
  font-family: 'KievitPro';
  src: url('../fonts/kievit-pro/KievitPro-ExtraLight.eot');
  src: local('KievitPro-ExtraLight'),
  url('../fonts/kievit-pro/KievitPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/kievit-pro/KievitPro-ExtraLight.woff') format('woff'),
  url('../fonts/kievit-pro/KievitPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'KievitPro';
  src: url('../fonts/kievit-pro/KievitPro-Light.eot');
  src: local('KievitPro-Light'),
  url('../fonts/kievit-pro/KievitPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/kievit-pro/KievitPro-Light.woff') format('woff'),
  url('../fonts/kievit-pro/KievitPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'KievitPro';
  src: url('../fonts/kievit-pro/KievitPro-Italic.eot');
  src: local('KievitPro-Italic'),
  url('../fonts/kievit-pro/KievitPro-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/kievit-pro/KievitPro-Italic.woff') format('woff'),
  url('../fonts/kievit-pro/KievitPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'KievitPro-Book';
  src: url('../fonts/kievit-pro/KievitPro-Book.eot');
  src: local('KievitPro-Book'),
  url('../fonts/kievit-pro/KievitPro-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/kievit-pro/KievitPro-Book.woff') format('woff'),
  url('../fonts/kievit-pro/KievitPro-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'KievitPro';
  src: url('../fonts/kievit-pro/KievitPro-Bold.eot');
  src: local('KievitPro-Bold'),
  url('../fonts/kievit-pro/KievitPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/kievit-pro/KievitPro-Bold.woff') format('woff'),
  url('../fonts/kievit-pro/KievitPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'KievitPro';
  src: url('../fonts/kievit-pro/KievitPro-ExtraBold.eot');
  src: local('KievitPro-ExtraBold'),
  url('../fonts/kievit-pro/KievitPro-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/kievit-pro/KievitPro-ExtraBold.woff') format('woff'),
  url('../fonts/kievit-pro/KievitPro-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'KievitPro';
  src: url('../fonts/kievit-pro/KievitPro-Black.eot');
  src: local('KievitPro-Black'),
  url('../fonts/kievit-pro/KievitPro-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/kievit-pro/KievitPro-Black.woff') format('woff'),
  url('../fonts/kievit-pro/KievitPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'KievitPro';
  src: url('../fonts/kievit-pro/KievitPro-Medium.eot');
  src: local('KievitPro-Medium'),
  url('../fonts/kievit-pro/KievitPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/kievit-pro/KievitPro-Medium.woff') format('woff'),
  url('../fonts/kievit-pro/KievitPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'KievitPro';
  src: url('../fonts/kievit-pro/KievitPro-Thin.eot');
  src: local('KievitPro-Thin'),
  url('../fonts/kievit-pro/KievitPro-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/kievit-pro/KievitPro-Thin.woff') format('woff'),
  url('../fonts/kievit-pro/KievitPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'KievitPro';
  src: url('../fonts/kievit-pro/KievitPro-Regular.eot');
  src: local('KievitPro-Regular'),
  url('../fonts/kievit-pro/KievitPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/kievit-pro/KievitPro-Regular.woff') format('woff'),
  url('../fonts/kievit-pro/KievitPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
